import {fetchData, log} from "./common/app";
import {cache} from "./archive";
import {resetBusy, setOtherBusy} from "./cameraHeader";
import {CamScrFAwBtn} from "./components/camScrButton";
import {Dropdown} from "./components/dropdown";
import {Alert} from "./components/alert";

let ptzDeadZone = 40;

export function resetAllPtzSessions() {
    for (const cameraId in cache) {
        if (!!cache[cameraId]?.params?.ptzIsActive) {
            releasePtz(cameraId);
        }
    }
}

export function blockPtz(camId, user) {
    log(`ptz`, `blocking ptz for camera`, camId);

    let $svg = $(cache[camId].svg);
    $svg.find('.ptz-btn').addClass('blocked');
    $svg.find('.ptz-btn').removeClass('used');

    let mode = $svg.attr(`data-mode`);
    if (mode.includes('ptz')) {
        switchPtz(camId);

        new Alert({
            parent: $svg.parent()[0],
            text: `Управление PTZ было перехвачено пользователем с более высоким приоритетом (${user})`
        }).display();
    }
}

export function unblockPtz(camId) {
    log(`ptz`, `unblocking ptz for camera`, camId);

    let $svg = $(cache[camId].svg);
    $svg.find('.ptz-btn').removeClass('blocked');
    $svg.find('.ptz-btn').removeClass('used');
    $svg.parent().find('.widget-alert-wr').remove();
}

export function informPtzUsed(camId) {
    let $svg = $(cache[camId].svg);
    $svg.find('.ptz-btn').addClass('used');
    $svg.find('.ptz-btn').removeClass('blocked');
}

export async function switchPtz(camId, ptzCb) {
    log(`ptz`, `switch ptz: ${camId}, ptz callback:`, typeof ptzCb === 'function')

    let $svg = $(cache[camId].svg);
    let mode = $svg.attr(`data-mode`);
    let $ptzControl = $svg.find('.ptz-control');
    let $area = $svg.find(`.ptz-area`);

    if (mode.length === 0) {
        $svg.find(`.ptz-btn`).addClass('active');
        $svg.find('.ptz-btn').removeClass('used');
        let resp = await sendAcquireRequest(camId);

        if (!resp.status) {
            // display message
            $svg.find(`.ptz-btn`).removeClass('active');

            $svg.parent().find('.widget-alert-wr').remove();

            let text = resp.error === 'camera busy' ?
                `Управление PTZ осуществляется пользователем (${resp.payload.user})` :
                `Произошла ошибка: ${resp.error}`;

            new Alert({
                parent: $svg.parent()[0],
                text: text,
            }).display();

            log(`ptz`, 'cant acquire ptz control', resp.error);

            return;
        }

        log(`ptz`, `switch ptz on`, $svg.attr('id'));

        $svg.attr(`data-mode`, 'ptz');
        $svg.find(`.ptz-btn`).addClass('active');
        setOtherBusy($svg, $svg.find(`.ptz-btn`)[0]);

        let ind = document.createElementNS("http://www.w3.org/2000/svg", "path");
        $(ind)
            .attr('transform', 'translate(281 150)')
            .attr('d', 'M19.7071 0.292893C19.3166 -0.0976311 18.6834 -0.0976311 18.2929 0.292893L11.9289 6.65685C11.5384 7.04738 11.5384 7.68054 11.9289 8.07107C12.3195 8.46159 12.9526 8.46159 13.3431 8.07107L18 3.41421V18H3.41421L8.07107 13.3431C8.46159 12.9526 8.46159 12.3195 8.07107 11.9289C7.68054 11.5384 7.04738 11.5384 6.65685 11.9289L0.292893 18.2929C-0.0976311 18.6834 -0.0976311 19.3166 0.292893 19.7071L6.65685 26.0711C7.04738 26.4616 7.68054 26.4616 8.07107 26.0711C8.46159 25.6805 8.46159 25.0474 8.07107 24.6569L3.41421 20H18V34.5858L13.3431 29.9289C12.9526 29.5384 12.3195 29.5384 11.9289 29.9289C11.5384 30.3195 11.5384 30.9526 11.9289 31.3431L18.2929 37.7071C18.6834 38.0976 19.3166 38.0976 19.7071 37.7071L26.0711 31.3431C26.4616 30.9526 26.4616 30.3195 26.0711 29.9289C25.6805 29.5384 25.0474 29.5384 24.6569 29.9289L20 34.5858V20H34.5858L29.9289 24.6569C29.5384 25.0474 29.5384 25.6805 29.9289 26.0711C30.3195 26.4616 30.9526 26.4616 31.3431 26.0711L37.7071 19.7071C38.0976 19.3166 38.0976 18.6834 37.7071 18.2929L31.3431 11.9289C30.9526 11.5384 30.3195 11.5384 29.9289 11.9289C29.5384 12.3195 29.5384 12.9526 29.9289 13.3431L34.5858 18H20V3.41421L24.6569 8.07107C25.0474 8.46159 25.6805 8.46159 26.0711 8.07107C26.4616 7.68054 26.4616 7.04738 26.0711 6.65685L19.7071 0.292893Z')
            .appendTo($ptzControl);

        let zoomInBtn = new CamScrFAwBtn({
            defClasses: "wic zoom-in-btn",
            x: 292,
            y: 130,
            parent: $ptzControl[0],
            action: () => {
                changeZoom(camId, 0.06);
            }
        }).display();
        let zoomOutBtn = new CamScrFAwBtn({
            defClasses: "wic zoom-out-btn",
            x: 292,
            y: 194,
            parent: $ptzControl[0],
            action: () => {
                changeZoom(camId, -0.06);
            }
        }).display();
        let presetBtn = new CamScrFAwBtn({
            defClasses: "wic preset-btn",
            x: 576,
            y: 8,
            parent: $ptzControl[0],
        }).display();
        new CamScrFAwBtn({
            defClasses: "wic preset-btn",
            x: 556,
            y: 8,
            parent: $ptzControl[0],
            action: () => {
                let pos = cache[camId].params.ptzDefaultPosition;
                /*if (pos.pan === 0 && pos.tilt === 0 && pos.zoom === 0) {
                    return;
                }*/

                log(`ptz`, `move to def ptz`, pos);

                moveToPosition(camId, pos);
            }
        }).display();
        if (typeof ptzCb === 'function') {
            new CamScrFAwBtn({
                defClasses: "wic preset-btn",
                x: 536,
                y: 8,
                parent: $ptzControl[0],
                action: () => ptzCb(cache[camId].ptz),
            }).display();
        }

        $area
            .css({'cursor': 'crosshair'})
            .click(e => {
                let svg = $svg[0];
                let pt = svg.createSVGPoint();
                pt.x = e.clientX;
                pt.y = e.clientY;
                let point = pt.matrixTransform(svg.getScreenCTM().inverse());

                let posX = point.x;
                let posY = point.y - 32;
                log(`ptz`, `ptz click`, {posX: posX, posY: posY});

                // let zoom = cache[camId].ptz.zoom;
                let dPan = (posX - 300) / 5300 /*(3600 + zoom * 50000)*/;//zoom 1.0=75000, zoom 0.0=3600
                let dTilt = (posY - 169) / 2600 /*(1800 + zoom * 20000)*/;//zoom 1.0=37000, zoom 0.0=1800

                if (Math.abs(dPan) + Math.abs(dTilt) < 0.0001) {
                    log(`ptz`, `ignored ptz move`, {dPan: dPan, dTilt: dTilt});
                    return;
                }
                moveCamera(camId, dPan, dTilt);
            });

        let presets = cache[camId]?.ptz?.presets || [];
        let presList = [];
        for (const [id, name] of Object.entries(presets)) {
            presList.push({
                name: name,
                action: () => fetchData(`/device/ptz/preset/${camId}/${id}`),
            });
        }

        log(`ptz`, `presetsList`, presList);

        let presetDropdown = new Dropdown({
            initiator: presetBtn.el,
            items: presList,
            appearance: 'ld',
            freeze: true,
        });

    } else if (mode.includes('ptz')) {
        log(`ptz`, `switch ptz off`, $svg.attr('id'));

        switchPtzOff(camId, $svg);
    }
}

export function checkAndSwitchPtzOff(camId){
    log(`ptz`, `try to switch ptz off`, camId);

    if (camId in cache) {
        let $svg = $(cache[camId].svg);
        let mode = $svg.attr(`data-mode`);
        if (mode.includes('ptz')) {
            switchPtzOff(camId, $svg);
        }
    }
}

export function switchPtzOff(camId, $svg) {
    let $ptzControl = $svg.find('.ptz-control');
    let $area = $svg.find(`.ptz-area`);

    $svg.attr(`data-mode`, '');
    $svg.find(`.ptz-btn`).removeClass('active');
    resetBusy($svg);

    $ptzControl.empty();
    $(`.my-dropdown`).remove();
    $area.unbind('click')
    $area.removeAttr('style');

    releasePtz(camId);
}

export async function sendAcquireRequest(id) {
    let resp = await fetchData(`/device/ptz/acquire/${id}/${appSession.cid}`);
    if (resp.status) {
        cache[id].ptz = resp.payload;
    }

    log(`ptz`, `acquire ptz control for ${id} res = ${resp.status}`, cache[id].ptz);

    return resp;
}

export function releasePtz(id) {
    log(`ptz`, `release ptz for ${id}`);

    fetchData(`/device/ptz/release/${id}/${appSession.cid}`);
}

function moveCamera(id, dpan, dtilt) {
    fetchData(`/device/ptz/move/${id}/${appSession.cid}?dpan=${dpan}&dtilt=${dtilt}`).then(r => {
        if (r.status) {
            cache[id].ptz = r.payload;
        }
    });
}

function changeZoom(id, dZoom) {
    fetchData(`/device/ptz/zoom/${id}/${appSession.cid}?dZoom=${dZoom}`).then(r => {
        if (r.status) {
            cache[id].ptz = r.payload;
        }
    });
}

export function moveToPosition(camId, pos) {
    fetchData(`/device/ptz/position/${camId}/${appSession.cid}`, pos).then(r => {
        if (r.status) {
            cache[camId].ptz = r.payload;
        }
    });
}

export function setDefaultPositionInCache(camId, pos) {
    cache[camId].params.ptzDefaultPosition = pos;

    log(`ptz`, `save def ptz to cache`, cache[camId]);
}