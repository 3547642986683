export function nameFromAuth(auth) {
    return auth && auth.includes(':') ? auth.split(':')[0] : auth;
}

/**
 * Проверка какая ОС используется по возможности плеера проигрывать формат apple
 * @return {boolean}
 */
export function isIOS() {
    const testVideo = document.createElement('video');
    const res = testVideo.canPlayType('application/vnd.apple.mpegurl');
    testVideo.remove();
    return !!res.length;
}