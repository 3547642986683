import {hideUrlSensData, log, logError} from "./app";
import {nameFromAuth} from "./tools";

export class PsmWebSocket {
    ws = null;
    queue = [];
    sendQueue = [];
    autoReconnect = false;
    subscriptions = [];
    wsFatalError = false;
    isReconnected = false;
    ping;
    prevState = -1;
    promtv;
    auth;
    subs = {};

    isWSCreated() {
        return this.ws !== null;
    }

    isWSStarted() {
        return this.isWSCreated() && this.ws.readyState === 1;
    }

    open(promtv, auth, backComp = false) {
        if (this.isWSCreated()) {
            return
        }

        this.promtv = promtv;
        this.auth = auth;

        let wsUrl = wsFromHttpUrl(promtv, auth) + '/ws';

        if (backComp) { // todo обратная совместимость
            log('ws_2', 'backward compatibility mode');
            try {
                wsUrl += `?real=${USER_INFO.login}`;
            } catch (e) {
            }
        }

        let wsUrlSafe = hideUrlSensData(wsUrl);
        log(`ws_2`, `starting`, wsUrlSafe, `(${nameFromAuth(auth)})`);

        try {
            this.ws = new WebSocket(wsUrl);
            this.wsFatalError = false;
            let psm_ws = this;

            psm_ws.ws.onopen = function (e) {
                log(`ws_2`, "connected", wsUrlSafe, `(${nameFromAuth(auth)})`);
                psm_ws.autoReconnect = true;
                psm_ws.prevState = 1;

                psm_ws.publish({type: "connect", data: {promtv: promtv, auth: auth}});

                while (psm_ws.sendQueue.length > 0) {
                    psm_ws.ws.send(psm_ws.sendQueue.shift());
                }

                if (psm_ws.isReconnected) {
                    psm_ws.publish({type: "reconnect", data: {promtv: promtv, auth: auth}});
                    for (const devId of Object.keys(psm_ws.subs)) {
                        if (devId === '/attention') {
                            psm_ws.sendSubDutyRequest();
                        } else {
                            psm_ws.sendSubDevRequest(devId);
                        }
                    }
                }
                psm_ws.isReconnected = true;

                psm_ws.ping = setInterval(() => {
                    psm_ws.ws.send(JSON.stringify({
                        type: 'ping'
                    }))
                }, 10_000)
            };

            psm_ws.ws.onmessage = function (event) {
                let msg = JSON.parse(event.data);
                if (typeof msg.data === 'object') {
                    msg.data.promtv = promtv;
                    msg.data.auth = auth;
                }
                // log(`ws`, promtv , `(${nameFromAuth(auth)})`, "<-- in msg", msg);
                psm_ws.handleInMsg(msg);
            };

            psm_ws.ws.onclose = function (event) {
                log(`ws_2`, "closed", wsUrlSafe, `(${nameFromAuth(auth)})`);

                if (psm_ws.prevState === 1) {
                    psm_ws.publish({type: "disconnect", data: {promtv: promtv, auth: auth}});
                }
                psm_ws.prevState = 3;

                if (psm_ws.autoReconnect) {
                    psm_ws.reconnect();
                }
            };

            psm_ws.ws.onerror = function (error) {
                log(`ws_2`, "ws error", wsUrlSafe, `(${nameFromAuth(auth)})`);
                psm_ws.reconnect();
            };

        } catch (e) {
            this.wsFatalError = true;
            logError(`ws_2`, 'ws creating error', wsUrlSafe, `${e.name} : ${e.message}`);
        }
    }

    reconnect() {
        let _this = this;
        this.ws = null;
        clearInterval(this.ping);
        setTimeout(function () {
            _this.open(_this.promtv, _this.auth);
        }, 10_000);
    }

    close() {
        log(`ws_2`, 'ws closing');
        clearInterval(this.ping);
        this.autoReconnect = false;
        this.isReconnected = false;
        if (this.isWSCreated()) {
            this.ws.close();
        }
        this.ws = null;
    }

    handleInMsg(msg) {
        this.publish(msg);
    }

    sendMsg(msg) {
        if (this.isWSStarted()) {
            this.ws.send(JSON.stringify(msg))
        } else if (!this.wsFatalError) {
            this.sendQueue.push(JSON.stringify(msg));
        }
    }

    // Запрос в ПромТВ на подписку изменения состояния устройства
    sendSubDevRequest(camId) {
        let msg = {
            type: 'subscribe',
            data: camId + '',
        };

        if (this.prevState !== 1) {
            this.sendQueue.push(JSON.stringify(msg));
            log(`ws_2`, this.promtv, `(${nameFromAuth(this.auth)}) subscribing request to promtv device state add to queue`, camId);
            return;
        }

        log(`ws_2`, this.promtv, `(${nameFromAuth(this.auth)}) send subscribing request to promtv device state`, camId);
        this.sendMsg(msg);
    }

    // Подписка на состояние устройства
    subDev(devId, cb) {
        if (devId in this.subs) {
            if (!this.subs[devId].includes(cb)) {
                this.subs[devId].push(cb);
            }
        } else {
            this.subs[devId] = [cb];
        }
        this.sendSubDevRequest(devId);

        let sa = this.subsArr();
        log(`ws_2 sub`, this.promtv, `(${nameFromAuth(this.auth)}) dev sub added, total:`, sa.length, sa);
    }

    // Отмена подписки на состояние устройства
    unsubDev(devId, cb) {
        if (!this.subs[devId]) {
            return;
        }

        let i = this.subs[devId].indexOf(cb);
        if (i >= 0) {
            this.subs[devId].splice(i, 1);
        }

        if (this.subs[devId].length === 0) {
            delete this.subs[devId];
            this.sendUnsubDutyRequest(devId);
        }

        let sa = this.subsArr();
        log(`ws_2 sub`, this.promtv, `(${nameFromAuth(this.auth)}) dev sub removed, total:`, sa.length, sa);
    }

    // Запрос в ПромТВ на подписку на события дежурного окна
    sendSubDutyRequest() {
        let msg = {type: 'subscribeAttention'};

        if (this.prevState !== 1) {
            this.sendQueue.push(JSON.stringify(msg));
            log(`ws_2`, this.promtv, `(${nameFromAuth(this.auth)}) subscribing request to promtv duty add to queue`);
            return;
        }

        log(`ws_2`, this.promtv, `(${nameFromAuth(this.auth)}) send subscribing request to promtv duty events`);

        this.sendMsg(msg);
    }

    // Подписка на события дежурного окна
    subDutyEvents(cb) {
        const d = '/attention';
        if (d in this.subs) {
            this.subs[d].push(cb);
        } else {
            this.subs[d] = [cb];
            this.sendSubDutyRequest();
        }

        let sa = this.subsArr();
        log(`ws_2 sub`, this.promtv, `(${nameFromAuth(this.auth)}) duty sub added, total:`, sa.length, sa);
    }

    // Запрос в ПромТВ на отмену подписки на события дежурного окна
    sendUnsubDutyRequest(camId) {
        log(`ws_2`, this.promtv, `(${nameFromAuth(this.auth)}) send unsubscribe request to promtv duty events`);
        this.sendMsg({
            type: 'unsubscribe',
            data: camId
        });
    }

    subscribeWsMsg(cb, camId) {
        if (this.subscriptions.includes(cb)) {
            log(`ws_2 sub`, this.promtv, 'sub not added, exists already', this.subscriptions.length, this.subscriptions);
            return;
        }

        this.subscriptions.push(cb);

        log(`ws_2 sub`, this.promtv, `(${nameFromAuth(this.auth)})`, camId, 'add ws msg sub, total:', this.subscriptions.length, this.subscriptions);

        this.queue.forEach(msg => {
            log(`ws_2`, this.promtv, `publish from queue for ${camId}`, msg);
            cb(msg);
        })
    }

    unsubscribeWsMsg(cb) {
        let index = this.subscriptions.indexOf(cb);
        if (index >= 0) {
            this.subscriptions.splice(index, 1);
            log(`ws_2 sub`, this.promtv, `(${nameFromAuth(this.auth)})`, 'ws msg sub removed, total:', this.subscriptions.length, this.subscriptions);
        }
    }

    publish(msg) {
        log(`ws_2 sub`, this.promtv, `(${nameFromAuth(this.auth)})`, `publish <--`, msg);

        /*if (subscriptions.length === 0) {
            queue.push(msg);
        }*/

        // подписка на устройства
        // if (this.subs?.[msg?.data?.devId]) {
        (this.subs[msg?.data?.devId] || []).forEach(cb => {
            if (typeof cb === 'function') {
                cb(msg);
            }
        })
        // }

        // подписка на события дежурного окна
        // if (this.subs?.['/attention'] && msg.type === 'attention') {
        if (msg.type === 'attention') {
            (this.subs['/attention'] || []).forEach(cb => {
                if (typeof cb === 'function') {
                    cb(msg);
                }
            })
        }

        //подписка на все события
        this.subscriptions.forEach(sub => {
            if (sub) {
                sub(msg);
            }
        })
    }

    clearData() {
        this.subscriptions = [];
        this.sendQueue = [];
        this.queue = [];
        this.subs = {};
    }

    subsArr() {
        return Object.values(this.subs).reduce((acc, ss) => acc.concat(ss), []);
    }

}

export function wsFromHttpUrl(promtvHttpUrl, auth) {
    const srvUrl = new URL(promtvHttpUrl);
    let prot = srvUrl.protocol.startsWith('https') ? 'wss' : 'ws';
    let port = !!srvUrl.port ? srvUrl.port : undefined;
    let authData = !!auth ? auth + '@' : '';

    return `${prot}://${authData}${srvUrl.hostname}${!!port ? ':' + port : ''}${srvUrl.pathname.length > 1 ? srvUrl.pathname : ''}`;
}





