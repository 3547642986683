import {debug, fetchData_v2, log, requestExport_v2} from "./common/app";
import dayjs from "dayjs";
import {getFullscreenElement} from "./common/fullscreen";
import {createSpeedControl, defaultState} from "./speedControls";
import {requestAndPrepareTimeLineData} from "./timeLineData2";
import TimelinesChart from "./timelines-chart";
import {Loader} from "./components/loader";
import {Dropdown} from "./components/dropdown";
import {DateRangePicker} from "./components/daterangepicker";
import {CamScrFAwBtn} from "./components/camScrButton";


export class Archive {
    $svg;
    devId;
    archiveNo = -1;
    interval = {};
    camConf;
    speedControl;
    timeLine;
    player;
    videoTime;
    exportQueue = [];
    active = false;

    constructor(promtv, auth, $svg, camConf, player) {
        this.promtv = promtv;
        this.auth = auth;
        this.$svg = $svg;
        this.devId = camConf.name;
        this.camConf = camConf;
        this.player = player;
    }

    async switch(isEnabled) {
        if (isEnabled) {
            this.switchOn();
        } else {
            await this.switchOff();
        }
    }

    switchOn() {
        log(`archive`, `switch archive on`, this.devId);

        this.$svg.attr(`data-mode`, 'archive');
        this.$svg.find(`.archive-btn`).addClass('active');

        let $ptzControl = this.$svg.find('.ptz-control');
        let timeLineHideBtn;

        this.interval = {
            start: dayjs().subtract(3, 'h').toDate(),
            end: dayjs().local().toDate(),
        }

        // выбираем архив по-умолчанию
        let def = Object.entries(this.camConf.archives).filter(([id, conf]) => conf.isDefault)[0][0];
        this.archiveNo = parseInt(def) || 0;
        this.displayInfo();

        // display archive select btn
        let archiveSelBtn = new CamScrFAwBtn({
            defClasses: "wic archive-sel-btn",
            x: 576,
            y: 8,
            parent: $ptzControl[0],
        }).display();

        let archiveList = [];
        for (const [id, data] of Object.entries(this.camConf.archives)) {
            if (data.isActive) {
                archiveList.push({
                    name: data.name,
                    action: () => {
                        log(`archive`, `switch to archive`, data);
                        let $exSpCnt = this.$svg.find(`.speed-control`);
                        this.archiveNo = parseInt(id);
                        this.displayInfo().then(() => {
                            $exSpCnt.remove();
                        });
                    }
                });
            }
        }

        let archiveDropDown = new Dropdown({
            appearance: 'ld',
            initiator: archiveSelBtn.el,
            items: archiveList,
            freeze: true,
            parent: this.$svg.parent()[0],
            selectedIndexSupplier: () => this.archiveNo,
        });

        let isTimeLineShown = true;
        timeLineHideBtn = new CamScrFAwBtn({
            defClasses: "wic timeline-hide-btn",
            x: 576,
            y: 350,
            parent: this.$svg[0],
            action: () => {
                if (isTimeLineShown) {
                    this.$svg.find('.time-chart').hide();
                    this.$svg.find('.speed-control').hide();
                } else {
                    this.$svg.find('.time-chart').show();
                    this.$svg.find('.speed-control').show();
                }
                isTimeLineShown = !isTimeLineShown;
            }
        }).display();

        this.active = true;
    }

    async switchOff() {
        log(`archive`, `switch archive off`, this.devId);

        this.$svg.attr(`data-mode`, '');
        this.$svg.find(`.archive-btn`).removeClass('active');

        //тестовая надпись с временем архива
        this.$svg.find('.play-time').css('display', 'none');

        this.$svg.find('.ptz-control').empty();
        $(`.my-dropdown`).remove();
        this.$svg.find('.timeline-hide-btn').remove();
        this.$svg.find(`.time-chart`).empty().show();
        this.$svg.find(`.speed-control`).empty();

        this.active = false;
        this.archiveNo = -1;
    }

    async displayInfo() {
        let loader = new Loader();
        loader.show(this.$svg[0]);

        let data = await requestAndPrepareTimeLineData(this.camConf, this.interval.start, this.interval.end, this.archiveNo);
        log(`archive`, 'timeline data ', data);

        loader.hide();

        let rootElement = !!getFullscreenElement() ? getFullscreenElement() : document.body;

        this.timeLine = this.createTimeline(data, rootElement);
        this.speedControl = createSpeedControl(this.$svg, (spd) => this.playChange(spd));
    }

    createTimeline(data, rootElement = document.body) {
        let $timeLineEl = this.$svg.find(`.time-chart`);
        $timeLineEl.empty();

        return TimelinesChart()
            .onSegmentClick(segment => this.segmentClickHandler(segment, this.archiveNo))
            .onMediaExport(this.mediaExportHandler())
            .onOverviewContext(this.overviewContextHandler())
            .zoomX([this.interval.start, this.interval.end])
            .width(600)
            .data(data)
            .rootElement(rootElement)
            ($timeLineEl[0])
    }

    segmentClickHandler(segment, archiveNo) {
        log(`archive`, "segment clicked: ", segment);

        let speed = 1;
        let start = segment.timeRange[0];

        defaultState(this.speedControl);
        this.play(start, speed, 0, archiveNo);
    }

    mediaExportHandler() {
        let _this = this;
        return function (event, segment) {
            new Dropdown({
                appearance: 'ru',
                parent: _this.$svg.parent()[0],
                items: [{
                    name: 'Экспорт',
                    action: () => {
                        let reqBody = {
                            "devId": _this.devId,
                            "start": dayjs(segment.timeRange[0]).utc().format('YYYYMMDD[T]HHmmss'),
                            "end": dayjs(segment.timeRange[1]).utc().format('YYYYMMDD[T]HHmmss'),
                            "source": _this.archiveNo,
                        }
                        requestExport_v2(_this.$svg, _this.exportQueue, _this.camConf, reqBody);
                        log(`archive`, `export video`, reqBody);
                    }
                }],
            }).display(event);
        }
    }

    overviewContextHandler() {
        let _this = this;
        return function (e) {
            new Dropdown({
                appearance: 'ru',
                parent: _this.$svg.parent()[0],
                items: [{
                    name: 'Изменить интервал',
                    action: function () {
                        $(`.daterangepicker:hidden`).remove();
                        new DateRangePicker(e.target, {
                                timePicker: true,
                                timePicker24Hour: true,
                                startDate: _this.interval.start,
                                endDate: _this.interval.end,
                                maxDate: dayjs().toDate(),
                                opens: "center",
                                drops: "up",
                                parent: /*document.body,*/_this.$svg.parent()[0],
                            }, (start, end, label) => {
                                let loader = new Loader();
                                loader.show(_this.$svg[0]);
                                _this.interval.start = start;
                                _this.interval.end = end;

                                requestAndPrepareTimeLineData(_this.camConf, _this.interval.start, _this.interval.end, _this.archiveNo).then(data => {
                                    log(`archive`, 'change overview, timeline data', data);
                                    _this.timeLine.overviewDomain([start, end]);
                                    _this.timeLine.data(data);
                                    loader.hide();
                                });
                            }
                        ).show();
                    }
                }]
            }).display(e);
        }
    }

    async stopPreviousVideo() {//todo sync camera.js, возможно не понадобится
        if (this.timeLine) {
            this.timeLine.dateMarker(undefined);
        }
    }

    async play(start, speed = 1, prePlay = 0, archiveNo = 0) {
        log(`archive`, `requesting archive video for camera ${this.devId},  from ${start}, speed ${speed}`);

        await this.stopPreviousVideo();

        let startDayjs = dayjs(start).utc().subtract(prePlay * speed, 'ms');
        let startDateTime = startDayjs.format('YYYYMMDD[T]HHmmss[.]SSS');

        // let rtspUrl = `/device/${cameraId}/archive/${archiveNo}/${startDateTime}/${speed}/webrtc`;
        // log(`archive`, `webRtc archive stream url:`, rtspUrl);

        this._play(startDateTime, speed, (videoTime) => {
            let dayjsTime = dayjs.utc(videoTime, "YYYYMMDD_HHmmss.SSS").local();

            if (!!this.videoTime) {
                let timeDiff = Math.abs(dayjsTime.toDate().getUTCSeconds() - this.videoTime.toDate().getUTCSeconds());
                if (timeDiff > 5) {
                    log(`archive`, 'time diff very large, sec', timeDiff);
                    // return
                }
            }

            this.timeLine.dateMarker(dayjsTime.toDate());
            this.displayTime(dayjsTime);

            this.videoTime = dayjsTime;
        })
    }

    displayTime(startDayjs) {
        //тестовая надпись с временем архива
        if (debug) {
            this.$svg.find('.play-time')
                .css('display', '')
                .text(startDayjs.format('YYYY-MM-DD[ ]HH[:]mm[:]ss[.]SSS'));
        }
    }

    _play(startDateTime, speed, cbFunc) {
        log(`archive`, `play archive: ${this.archiveNo}, devId:`, this.devId, startDateTime, speed);

        this.player.play({
            promtv: this.promtv,
            auth: this.auth,
            camId: this.devId,
            url: `${this.promtv}/device/${this.devId}/archive/${this.archiveNo}/${startDateTime}/${speed}`,
            onTimeSyncCb: cbFunc,
            video: this.$svg.find(`video`)[0],
        });
    }

    playChange(speed) {
        fetchData_v2(`${this.promtv}/device/${this.camConf.name}/archive/${this.archiveNo}/control/${speed}/${this.player.cid}`);
    }

    close() {

    }

    isActive() {
        return this.active;
    }
}