import {fetchData, log, logError, server, setServer} from "./app";


export let auth = "demo:demo";
export let appSession = undefined;


export function setAuth(authData) {
    auth = authData;
    log(`session`, `server auth set`);
}

export function setSession(session) {
    appSession = session;
}

export async function requestAppSession() {
    if (appSession !== undefined || window.promtv?.page === 'login') {
        return;
    }

    // определяем клиента
    try {
        if (CLIENT_TYPE_18 === 'promtv') {
            setServer(location.origin);
            appSession = (await fetchData(`/user/session`)).payload;
            log(`session`, `promtv client`, appSession);
        }

    } catch (e1) {
        if (server === undefined) {
            return
        }

        appSession = (await fetchData(`/user/session`)).payload;
        log(`session`, `scada client`, appSession);
    }
}